<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="white" class="pb-2 pt-1">
        <img
          class="w-52 -ml-6 -mt-8"
          :src="require('@/assets/logo-transparent.png')"
          alt=""
        />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="pt-6">
        <slot />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/vue";

export default {
  props: {
    title: String,
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
  },
};
</script>

<style>
ion-content {
  /* --ion-background-color: #fcf6eb; */
  --ion-background-color: #ffffff;
}
</style>
